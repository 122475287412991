<template>
    <div>
        <div class="card st-framework-card">

            <div class="card-header">
                <table style="border-collapse: collapse; width: 100%;">
                    <tr>
                        <td style="margin: 0; padding: 0 0 0 1rem; width: 50px;">
                            <font-awesome-icon :icon="icon" size="sm"/>
                        </td>
                        <td style="text-align: center;">
                            {{ $i18n.tc('translations.' + name, 2) + " (" + (project ? project.label : $i18n.t('translations.No Project Found')) + ")" }}
                        </td>
                        <td style="width: 50px;"/>
                    </tr>
                </table>
            </div>

            <div class="card-body p-0" v-if="pageLoaded">
                <Table :key="'component-table-'+tableReload" header-class="light" footer-class="light" body-class=""
                       with-filter with-pagination no-state-loading autofocus :lines-per-page=35
                       :rows="processedEdges"
                       :fields="fields.tableFields" :actions="actions.tableActions" :state="state"
                       v-on:clear-selection="clearSelection"
                       v-on:import-rfem-files="ImportRFEMFiles"
                       v-on:select-all="selectAll"
                       v-on:select-component="selectComponent"
                       v-on:zoom-component="zoomComponent"
                       v-on:zoom-reset="zoomReset"/>
            </div>

        </div>
    </div>
</template>

<script>
import Table from "@/components/Table";

/* global sketchup:false */

export default {
    name: 'RFEM',
    components: {
        Table,
    },
    props: ['name', 'icon', 'api', 'state'],
    data() {
        return {
            pageLoaded: false,
            project: null,
            fields: null,
            actions: null,
            dropBoxLocation: "",
            tableReload: 0,
            processedEdges: [],
        }
    },
    created() {
        this.state.loading = true;
        window.vm.Tool = this;
        sketchup.getToolData();
    },
    methods: {
        clearSelection() {
            sketchup.clearSelection();
        },
        fetchData(projectId) {
            this.$http.get(this.api + '/manage_rfem/' + projectId, {}).then((res) => {
                this.project = res.data.project;
                this.actions = res.data.actions;
                this.fields = res.data.fields;
                this.dropBoxLocation = res.data.dropBoxLocation;
                this.tableReload++;
                this.pageLoaded = true;
                this.state.loading = false;
            }).catch((error) => {
                console.log("RFEM:fetchData():error:", error);
            });
        },
        getToolDataCallback(data) {
            this.fetchData(data.project_id);
        },
        ImportRFEMFiles(data) {
            sketchup.ImportRFEMFiles((data['clear-model'] === '1'), this.dropBoxLocation);
        },
        ImportRFEMFilesCallback(data) {
            let response = JSON.parse(data);

            this.$worker.run((rawEdges) => {
                let Edges = [];

                rawEdges.forEach(edge => {
                    Edges.push({
                        'entity_id': edge.entity_id,
                        'id': edge.id,
                        'cross_section': edge.cross_section,
                        'pt1': edge.pt1,
                        'pt2': edge.pt2,
                        'length': edge.length.replace('.',','),
                    });
                });

                return Edges;
            }, [response.edges]).then(data => {
                this.processedEdges = data;
                this.tableReload++;
                this.pageLoaded = true;
                this.state.loading = false;
            }).catch(error => {
                console.log("FREM:ImportRFEMFilesCallback():error:", error);
            });
        },
        selectAll() {
            sketchup.selectAll();
        },
        selectComponent(component) {
            sketchup.selectComponent(component.entity_id);
        },
        zoomComponent(component) {
            sketchup.renderingOption('InactiveHidden', true);
            sketchup.renderingOption('InstanceHidden', true);
            sketchup.zoomExtents(component.entity_id);
        },
        zoomReset() {
            sketchup.renderingOption('InactiveHidden', false);
            sketchup.renderingOption('InstanceHidden', false);
            sketchup.zoomExtents();
        },
    }
}
</script>

<style scoped>
</style>
